<!-- Composant affichant la bulle de notif d'alerte, à partir de la section json de i18n -->
<template>
    <div class="alert alert-danger">{{ message_erreur }}</div>
</template>

<script type="text/javascript">
	export default {
        props:[
            'messageI18n',
        ],

		computed: {
            message_erreur: function() {
                return this.getTrad(this.messageI18n)
            }
		},
	}
</script>
